import { useEffect, useRef, useState } from "react";
import videojs from "video.js";

type Props = {
  sources: { src: string; type: string }[];
};

export const VideoPlayer = (props: Props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { sources } = props;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-theme-fantasy");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        {
          controls: true,
          autoplay: true,
          preload: "auto",
          responsive: true,
          muted: true,
          fill: true,
          sources: [sources?.[0]],
        },
        () => {
          player.on("ended", () => {
            setIndex((i) => (sources?.[i + 1] ? i + 1 : i));
          });

          player.on("error", (ev: {}) => {
            console.log(ev);
          });
        }
      ));
    } else {
      const player = playerRef.current;

      player.autoplay(true);

      player.src(sources?.[0]);
    }
  }, [sources, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    if (sources?.[index]) {
      const player = playerRef.current;
      player.src(sources[index]);
    }
  }, [index, sources]);

  return (
    <div data-vjs-player className="w-[100vw] h-[100vh]">
      <div ref={videoRef} className="w-full h-full" />
    </div>
  );
};
